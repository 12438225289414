import consumer from "./consumer";

consumer.subscriptions.create("SendingChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    // console.log("Connected to the room!");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    $("#order_" + data.id).html("").append(data.html);
  }
});
