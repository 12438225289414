import consumer from "./consumer"

consumer.subscriptions.create("ReportChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    // console.log("Connected to the room!");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // $(".print_object_reports_" + data.id).replaceWith(data.html);
  }
});
