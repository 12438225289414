$(document).ready(function() {
  collapseIconChange();
  colspans();
  popover();
  selectpicker();
  fileUploadsCountdown();
  toggleFields();
  initilizeUppy();
  toggles();
  tooltip();
  unloadPage();
  loadPage();
  updateScale();
})

$(document).ajaxComplete(function() {
  initilizeUppy();
  showHideDeleteBtn();
  updateScale();
});

window.loadPage = function() {
  if ($("#confirm_btn").length > 0) {
    // Se è ancora presente il pulsante di conferma
    $('#modal_page_load').modal('show')
  }
}

window.unloadPage = function() {
  $("html").bind("mouseleave", function () {
    if ($("#confirm_btn").length > 0 && $(".print_object_image").length == $("#confirm_btn").data('filecount')) {
      // Se è ancora presente il pulsante di conferma e ho caricato tutti i file
      $('#modal_page_unload').modal('show')
      // $("html").unbind("mouseleave");
    }
  });
}

window.updateScale = function() {
  $(".scale_select").each(function(i,el) {
    $(el).change(function() {
      id = $(this).data('id');
      token = $(this).data('token');
      url = "/update_scale/" + token + "/" + id;
      $.ajax({
        type: 'PATCH',
        url: url,
        beforeSend: function() {
          $(this).prop("disabled", true);
        },
        error: function(data) {
          $(this).prop("disabled", false);
          $("#p_" + id).removeClass('alert-success').addClass('alert-danger').fadeIn('slow');
          $("#p_" + id).html('<span>Errore nel cambio della scala. Riprovare!</span>');
          setTimeout(function() { $("#p_" + id).fadeOut('slow'); }, 3000);
        },
        success: function(data) {
          $(this).prop("disabled", false);
          if (data['code'] == '500') {
            $("#p_" + id).removeClass('alert-success').addClass('alert-danger').html('<span>Errore nel cambio della scala. Riprovare!</span>');
          } else if (data['code'] == '400') {
            $("#p_" + id).removeClass('alert-success').addClass('alert-danger').html('<span>Commessa già confermata, non è possibile aggiornare la scala.</span>');
          }
          $("#p_" + id).fadeIn('slow');
          setTimeout(function() { $("#p_" + id).fadeOut('slow'); }, 3000);
        },
        data: { scale: $(this).val() },
        dataType: 'json'
      });
    });
  });
}

window.showHideDeleteBtn = function() {
  if ($("#confirm_btn").length == 0) {
    $('.delete_btn').addClass('d-none');
    $('.scale_select').addClass('d-none');
    $('.scale_dd').removeClass('d-none');
  }
}

window.collapseIconChange = function() {
  $("[data-toggle='collapse']").each(function(i,el) {
    $(el).click(function() {
      target = $($(el).data('target'));
      children = $(el).find("svg");
      if ($(el).data('parent') && !target.hasClass('show')) {
        $('.fa-caret-square-down').each(function(j, obj) {
          $(obj).removeClass('fa-caret-square-up');
          $(obj).addClass('fa-caret-square-down');
        });
      }
      if (children.hasClass('fa-caret-square-up')) {
        children.removeClass('fa-caret-square-up').addClass('fa-caret-square-down');
      } else {
        children.removeClass('fa-caret-square-down').addClass('fa-caret-square-up');
      }
    });
  });
}

window.colspans = function() {
  $('td.colspan').each(function(i, el) {
    var count = 0
    $(el).closest('table').find('tr th').each(function(i, el) {
      if ($(el).attr('colspan')) {
        count += parseInt($(el).attr('colspan'));
      } else {
        count += 1;
      }
    });
    $(el).attr('colspan', count);
  });
}

window.fileUploadsCountdown = function() {
  $('.file-uploads-countdown').each(function(i, el){
    var countdown = $(el);
    var seconds_left = countdown.data('seconds-left');
    var decrement = function() {
      seconds_left -= 1;
      var days = ("0" + parseInt(seconds_left / (24 * 3600))).slice(-2);
      var hours = ("0" + parseInt((seconds_left % (24 * 3600)) / 3600)).slice(-2);
      var minutes = ("0" + parseInt((seconds_left % 3600) / 60)).slice(-2);
      var seconds = ("0" + parseInt(seconds_left % 60)).slice(-2);
      countdown.find('.days-0').html(days[0]);
      countdown.find('.days-1').html(days[1]);
      countdown.find('.hours-0').html(hours[0]);
      countdown.find('.hours-1').html(hours[1]);
      countdown.find('.minutes-0').html(minutes[0]);
      countdown.find('.minutes-1').html(minutes[1]);
      countdown.find('.seconds-0').html(seconds[0]);
      countdown.find('.seconds-1').html(seconds[1]);
      if(seconds_left == 0) {
        clearInterval(interval);
      }
    }
    if(seconds_left > 0) {
      var interval = setInterval(function() {
        decrement();
      }, 1000);
    }
  });
}

window.initilizeUppy = function() {
  $(".drag-drop-area.show").each(function(i, el) {
    $(el).removeClass("show");
    const Uppy = require('@uppy/core')
    const XHRUpload = require('@uppy/xhr-upload')
    const Dashboard = require('@uppy/dashboard')
    require('@uppy/core/dist/style.css')
    require('@uppy/dashboard/dist/style.css')
    const Italian = require('@uppy/locales/lib/it_IT')
    const uppy = Uppy({
      locale: Italian,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: 1048576000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        // allowedFileTypes: ['image/*', 'application/pdf']
        allowedFileTypes: ['application/pdf']
      }
    })
    uppy.use(Dashboard, {
      target: el,
      inline: true,
      height: 300
    });
    uppy.use(XHRUpload, {
      endpoint: $(el).data('url'),
      timeout: 180 * 1000
    })
    uppy.on('complete', (result) => {
      code = result.successful[0].response.body['code']
      data = result.successful[0].response.body['html']
      id = result.successful[0].response.body['id']
      if (code == 200) {
        $('.print_object_detail_' + id).html(data)
        uppy.reset();
        updateScale();
      } else {
        $('.print_object_detail_' + id).append('<p class="alert alert-danger">' + data + '</p>');
        updateScale();
      }
    })
    $('.uppy-Dashboard-poweredBy').hide();
  });
}

window.popover = function() {
  $('[data-toggle="popover"]').popover();
}

window.selectpicker = function() {
  $('.selectpicker').selectpicker();
}

window.toggleFields = function() {
  $("select[data-behaviour='toggle_fields']").each(function(i, el) {
    toggleFieldsVisibility(el);
    $(el).change(function() {
      toggleFieldsVisibility(el);
    });
  });
}

window.toggleFieldsVisibility = function() {
  $("[data-dependency]").each(function(i,el) {
    target = $(this).data("dependency");
    values = $(this).data('dependencyvalue').toString().split(',');
    condition = $(this).data('dependencycondition');
    target_value = $("#" + target).val();
    switch(condition) {
      case 'Not-equals':
        if ($.inArray( target_value, values ) == -1 && target_value != '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
        break;
      case 'Equals':
        if ($.inArray( target_value, values ) != -1 && target_value != '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
        break;
      case 'Contains':
        if ( String(target_value).indexOf(String(values)) >= 0 && target_value != '' ) {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
      case 'Does not contain':
        if ( String(target_value).indexOf(String(values)) == -1 && target_value != '' ) {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
      case 'Starts with':
        if (String(target_value).match("^" + String(values)) && target_value != '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
      case 'Does not start with':
        if ( String(target_value).match("^" + String(values)) == null && target_value != '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
      case 'IsNil':
        if (target_value == '' && values == '') {
          $(el).show();
          $(el).find('*').filter(':input:first').prop( "disabled", false );
        } else {
          $(el).hide();
          $(el).find('*').filter(':input:first').prop( "disabled", true );
        }
        break;
      }
  });
}

window.toggles = function() {
  $("[data-behaviour='toggle']").each(function(i, el) {
    var div = $(el);
    div.children('a').on('click', function () {
      var url = $(this).data('url');
      $.ajax({
        type: 'PATCH',
        url: url,
        beforeSend: function() {
          $(this).removeClass('btn-success').removeClass('btn-danger').addClass('btn-warning');
        },
        success: function(data) {
          div.replaceWith(data);
        },
        dataType: 'html'
      });
    });
  });
}

window.tooltip = function() {
  $('[data-toggle="tooltip"]').tooltip();
}
